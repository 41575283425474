<template>
  <div class="content flex-column">
    <div class="flex-space-between">
      <div class="operation flex-center-v">
        <el-input v-model="searchForm.departmentName" placeholder="请输入项目名称" size="small" style="width:140px;" clearable></el-input>
      </div>
      <div class="search" style="margin-bottom: 10px">
        <el-button type="primary" size="small" style="width:80px;" @click="exportTaiBan">导出</el-button>
        <el-button type="primary" size="small" style="width:80px;" @click="searchTaiBan">查询</el-button>
      </div>
    </div>
    <div class="cont flex-1-auto">
      <el-table :data="TaiBantableData" height="100%" :header-cell-style="{background:'#f5f5f5',color:'#333333'}">
        <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
        <el-table-column align="center" prop="name" label="项目名称" show-overflow-tooltip>
          <template slot-scope="{row}">
            <div style="text-align: left;width: 100%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"> {{ row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="countList" label="吊篮总台班">
          <template slot-scope="{row}">
            <span>{{ row.countList[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="countList" label="吊篮巡检台班">
          <template slot-scope="{row}">
            <span>{{ row.countList[1] }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="countList" label="吊篮在用台班">
          <template slot-scope="{row}">
            <span>{{ row.countList[2] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import apis from '../api/security-check.js'
import {downloadFile} from '@/utils/utils'
import BaseURL from '@/apis/baseurl'
export default {
  name: "two",

  components: {},

  props: {},

  data() {
    return {
      searchForm: {
        departmentName: ''
      },
      TaiBantableData: []
    }
  },

  computed: {
    ...mapGetters(['companyId', 'optionItem'])
  },

  watch: {
    companyId() {
      this.initTaiBan()
    }
  },

  mounted() {
    this.initTaiBan()
  },

  methods: {
    initTaiBan() {
      const params = {
        departmentId: this.optionItem.departmentId,
        departmentType: this.optionItem.departmentType,
        departmentName: this.searchForm.departmentName
      }
      apis.hangingBasketTotal(params).then(res => {
        this.TaiBantableData = res
      })
    },
    searchTaiBan() {
      this.initTaiBan()
    },
    exportTaiBan(){
      const params = {
        departmentId: this.optionItem.departmentId,
        departmentType: this.optionItem.departmentType,
        departmentName: this.searchForm.departmentName
      }
      const url = BaseURL + `/admin/public/exportTotal?departmentType=${params.departmentType}&departmentId=${params.departmentId}&departmentName=${params.departmentName}`
      downloadFile(url, {}, '吊篮综合台班.xls')
    },
  }
}
</script>
